<template>
  <b-card>
    <b-card-title class="h3 mb-8">{{ $t('label.overview') }}</b-card-title>

    <div>
      <template v-for="(item, index) in displayFields">
        <div :key="index" class="mb-4">
          <div class="font-weight-bolder text-capitalize mb-1">
            {{ item.label }}
          </div>
          <component
            v-if="item.component"
            :is="item.component"
            :value="value.chatGroup[item.property]"
          >
          </component>
          <div v-else class="text-end text-gray-600">
            {{ value.chatGroup[item.property] }}
          </div>
        </div>
      </template>
    </div>
  </b-card>
</template>

<script>
  import AppActivationStatusLabel from '@/views/components/bases/AppActivationStatusLabel';
  import { APP_COMPONENT_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'ChatHistoryOverviewCard',
    components: {
      AppActivationStatusLabel
    },
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            chatGroup: {}
          };
        }
      }
    },
    data: () => ({
      displayFields: [
        {
          label: i18nHelper.getMessage('label.name'),
          property: 'name'
        },
        {
          label: i18nHelper.getMessage('label.castingRole'),
          property: 'castingRole'
        },
        {
          label: i18nHelper.getMessage('label.active'),
          property: 'isActive',
          component: APP_COMPONENT_NAME.APP_ACTIVATION_STATUS_LABEL
        },
        {
          label: i18nHelper.getMessage('label.createdAt'),
          property: 'createdAt'
        }
      ]
    })
  };
</script>

<style></style>
