<template>
  <b-card class="chat-history-messages-card">
    <!-- begin:: Card Header -->
    <template #header>
      <b-row no-gutters class="justify-content-between">
        <b-col cols="10">
          <h3>{{ value.chatGroup.name }}</h3>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button @click="onClickRefresh">
            <i class="flaticon2-refresh-arrow p-0"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <!-- end:: Card Header -->

    <!-- begin:: Card Body -->
    <div
      v-if="value.chatGroupMessage.length > 0"
      class="chat-history-messages-card__messages_wrapper"
    >
      <template v-for="(item, index) in value.chatGroupMessage">
        <div
          :key="index"
          class="d-flex mb-10"
          :class="[
            getUserPosition(item.userId) == 'left'
              ? 'justify-content-start'
              : 'justify-content-end'
          ]"
        >
          <div
            class="d-flex flex-column"
            :class="[
              getUserPosition(item.userId) == 'left'
                ? 'align-items-start'
                : 'align-items-end'
            ]"
          >
            <div class="d-flex align-items-center mb-3">
              <!-- begin:: Avatar -->
              <div
                :class="[
                  getUserPosition(item.userId) == 'left' ? 'order-1' : 'order-2'
                ]"
              >
                <b-avatar
                  v-if="getUserDetails(item.userId).headshotFile.mediaPath"
                  size="3.5em"
                  :src="getUserDetails(item.userId).headshotFile.mediaPath"
                ></b-avatar>
                <b-avatar
                  v-else
                  size="3.5em"
                  :text="getShortName(getUserDetails(item.userId).displayName)"
                ></b-avatar>
              </div>
              <!-- end:: Avatar -->

              <!-- begin:: User -->
              <div
                class="d-flex"
                :class="[
                  getUserPosition(item.userId) == 'left' ? 'order-2' : 'order-1'
                ]"
              >
                <div
                  class="h5 font-weight-bolder text-gray-900 text-hover-primary px-4"
                  :class="[
                    getUserPosition(item.userId) == 'left'
                      ? 'order-1'
                      : 'order-2'
                  ]"
                >
                  {{ getUserDetails(item.userId).displayName }}
                </div>
                <span
                  class="text-muted"
                  :class="[
                    getUserPosition(item.userId) == 'left'
                      ? 'order-2'
                      : 'order-1'
                  ]"
                  >{{ item.createdAt }}</span
                >
              </div>
              <!-- end:: User -->
            </div>

            <!-- begin:: Message -->
            <div
              v-if="
                [chatMessageType.TEXT, chatMessageType.INFO].includes(
                  item.chatMessageType
                )
              "
              class="p-5 rounded bg-light-primary text-dark max-w-400px font-weight-bold"
              :class="[
                getUserPosition(item.userId) == 'left'
                  ? 'text-left'
                  : 'text-right'
              ]"
            >
              {{
                item.message || `(${$t('message.systemNoMessageDisplayed')})`
              }}
            </div>
            <div
              v-else
              class="p-5 rounded bg-light-primary text-dark max-w-400px"
              :class="[
                getUserPosition(item.userId) == 'left'
                  ? 'text-left'
                  : 'text-right'
              ]"
            >
              <ChatHistoryAttachment
                :value="item.media"
              ></ChatHistoryAttachment>
            </div>
            <!-- end:: Message -->
          </div>
        </div>
      </template>
    </div>
    <div v-else class="text-center">
      <span>{{ $t('message.thereAreNoMessagesToShow') }}</span>
    </div>

    <!-- end:: Card Body -->

    <!-- begin:: Card Footer -->
    <template #footer>
      <div class="text-center text-gray-600 font-weight-bold">
        {{ $t('message.onlyPreviewChatMessages') }}
      </div>
    </template>
    <!-- end:: Card Footer -->
  </b-card>
</template>

<script>
  import ChatHistoryAttachment from '@/views/components/chat-history/ChatHistoryAttachment';
  import { CHAT_MESSAGE_TYPE } from '@/core/constants/enums';

  export default {
    name: 'ChatHistoryMessagesCard',
    components: {
      ChatHistoryAttachment
    },
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            chatGroup: {}
          };
        }
      }
    },
    data: () => ({
      chatMessageType: CHAT_MESSAGE_TYPE
    }),
    methods: {
      onClickRefresh() {
        this.$parent.getChat(this.value.chatGroup.id);
      },
      getUserDetails(id) {
        var user = this.value.chatGroup.users.find((x) => x.userId == id);

        return {
          headshotFile: user.headshotFile,
          displayName: user.displayName
        };
      },
      getUserPosition(id) {
        return this.value.chatGroup.users.find((x) => x.userId == id).position;
      },
      getShortName(name) {
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      }
    }
  };
</script>

<style lang="scss">
  .chat-history-messages-card {
    .chat-history-messages-card__messages_wrapper {
      max-height: 400px;
      overflow: auto;
    }
  }
</style>
