var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"chat-history-messages-card",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',{staticClass:"justify-content-between",attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"10"}},[_c('h3',[_vm._v(_vm._s(_vm.value.chatGroup.name))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('b-button',{on:{"click":_vm.onClickRefresh}},[_c('i',{staticClass:"flaticon2-refresh-arrow p-0"})])],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center text-gray-600 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('message.onlyPreviewChatMessages'))+" ")])]},proxy:true}])},[(_vm.value.chatGroupMessage.length > 0)?_c('div',{staticClass:"chat-history-messages-card__messages_wrapper"},[_vm._l((_vm.value.chatGroupMessage),function(item,index){return [_c('div',{key:index,staticClass:"d-flex mb-10",class:[
          _vm.getUserPosition(item.userId) == 'left'
            ? 'justify-content-start'
            : 'justify-content-end'
        ]},[_c('div',{staticClass:"d-flex flex-column",class:[
            _vm.getUserPosition(item.userId) == 'left'
              ? 'align-items-start'
              : 'align-items-end'
          ]},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('div',{class:[
                _vm.getUserPosition(item.userId) == 'left' ? 'order-1' : 'order-2'
              ]},[(_vm.getUserDetails(item.userId).headshotFile.mediaPath)?_c('b-avatar',{attrs:{"size":"3.5em","src":_vm.getUserDetails(item.userId).headshotFile.mediaPath}}):_c('b-avatar',{attrs:{"size":"3.5em","text":_vm.getShortName(_vm.getUserDetails(item.userId).displayName)}})],1),_c('div',{staticClass:"d-flex",class:[
                _vm.getUserPosition(item.userId) == 'left' ? 'order-2' : 'order-1'
              ]},[_c('div',{staticClass:"h5 font-weight-bolder text-gray-900 text-hover-primary px-4",class:[
                  _vm.getUserPosition(item.userId) == 'left'
                    ? 'order-1'
                    : 'order-2'
                ]},[_vm._v(" "+_vm._s(_vm.getUserDetails(item.userId).displayName)+" ")]),_c('span',{staticClass:"text-muted",class:[
                  _vm.getUserPosition(item.userId) == 'left'
                    ? 'order-2'
                    : 'order-1'
                ]},[_vm._v(_vm._s(item.createdAt))])])]),(
              [_vm.chatMessageType.TEXT, _vm.chatMessageType.INFO].includes(
                item.chatMessageType
              )
            )?_c('div',{staticClass:"p-5 rounded bg-light-primary text-dark max-w-400px font-weight-bold",class:[
              _vm.getUserPosition(item.userId) == 'left'
                ? 'text-left'
                : 'text-right'
            ]},[_vm._v(" "+_vm._s(item.message || ("(" + (_vm.$t('message.systemNoMessageDisplayed')) + ")"))+" ")]):_c('div',{staticClass:"p-5 rounded bg-light-primary text-dark max-w-400px",class:[
              _vm.getUserPosition(item.userId) == 'left'
                ? 'text-left'
                : 'text-right'
            ]},[_c('ChatHistoryAttachment',{attrs:{"value":item.media}})],1)])])]})],2):_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('message.thereAreNoMessagesToShow')))])])])}
var staticRenderFns = []

export { render, staticRenderFns }