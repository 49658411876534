<template>
  <b-card class="chat-history-users-card">
    <!-- begin:: Title -->
    <b-card-title class="h3">{{ $t('label.users') }}</b-card-title>
    <!-- end:: Title -->

    <!-- begin:: Users -->
    <div class="chat-history-users-card__users_wrapper">
      <template v-for="(item, index) in value.chatGroup.users">
        <div :key="index" class="d-flex align-items-center py-4">
          <!-- begin:: Avatar -->
          <div>
            <b-avatar
              v-if="item.headshotFile.mediaPath"
              size="4em"
              :src="item.headshotFile.mediaPath"
            ></b-avatar>
            <b-avatar
              v-else
              size="4em"
              :text="getShortName(item.displayName)"
            ></b-avatar>
          </div>
          <!-- end:: Avatar -->

          <!-- begin:: Details -->
          <div class="pl-5">
            <AppFeatureDetailsRelatedButton
              :value="getUserDetails(item)"
              :config="{ to: routeName.PROFILE_DETAILS_DETAILS }"
              :appFeatureDetailsRelatedButtonDisabled="
                appFeatureDetailsRelatedButtonDisabled
              "
              class="font-weight-bold h6 mb-2 text-decoration-none text-secondary text-hover-primary"
            ></AppFeatureDetailsRelatedButton>

            <div class="font-weight-bold text-gray-600">
              {{ item.companyName || '-' }}
            </div>
          </div>
          <!-- begin:: Details -->
        </div>
      </template>
    </div>
    <!-- end:: Users -->
  </b-card>
</template>

<script>
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';

  export default {
    name: 'ChatHistoryUsersCard',
    components: {
      AppFeatureDetailsRelatedButton
    },
    mixins: [commonMixin],
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            chatGroup: {}
          };
        }
      }
    },
    data: () => ({
      routeName: ROUTE_NAME
    }),
    computed: {
      appFeatureDetailsRelatedButtonDisabled() {
        return !this.checkAccessRight(MODULE.USER, [ACTION.USER_READ]);
      }
    },
    methods: {
      getUserDetails(item) {
        return {
          id: item.userId,
          name: item.displayName
        };
      },
      getShortName(name) {
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      }
    }
  };
</script>

<style lang="scss">
  .chat-history-users-card {
    .chat-history-users-card__users_wrapper {
      max-height: 170px;
      overflow: auto;
    }
  }
</style>
