<template>
  <b-card no-body class="pointer h-100 shadow-sm card-custom rounded-0 overlay">
    <div class="card-body p-0">
      <div class="overlay-wrapper">
        <div
          :class="`${
            checkIsImage(value.mediaFile.mediaPath) ? 'overflow-hidden' : 'p-3'
          }`"
        >
          <div
            class="h-100px h-sm-150px h-lg-200px h-xl-250px"
            :class="`${
              checkIsImage(value.mediaFile.mediaPath)
                ? 'document-image'
                : 'document'
            }`"
            :style="`background-image: url('${getAttachmentThumbnail(
              value.mediaFile.mediaPath
            )}');`"
          ></div>
        </div>
        <span class="d-block py-3 px-4 pointer font-weight-bold">{{
          value.mediaName
        }}</span>
      </div>
      <div class="overlay-layer">
        <div class="text-center">
          <AppButtonDocumentView
            :attachmentUrl="value.mediaFile.mediaPath"
            :fileType="fileExtension"
            :buttonType="2"
          ></AppButtonDocumentView>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'ChatHistoryAttachment',
    components: {
      AppButtonDocumentView
    },
    props: {
      value: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    computed: {
      fileExtension() {
        return uiHelper.getFileExtension(this.value.mediaFile.mediaPath);
      }
    },
    methods: {
      checkIsImage(fileUrl) {
        let isImage = false;

        if (uiHelper.getFileExtension(fileUrl) == 'image') {
          isImage = true;
        }

        return isImage;
      },
      getAttachmentThumbnail(fileUrl) {
        let type = fileUrl.split('.').pop();
        var src = '/media/svg/files/jpg.svg';

        if (['jpeg', 'jpg', 'gif', 'png', 'webp'].includes(type)) {
          src = fileUrl;
        } else if (type == 'pdf') {
          src = '/media/svg/files/pdf.svg';
        } else if (['docx', 'doc', 'pptx', 'ppt'].includes(type)) {
          src = '/media/svg/files/doc.svg';
        }
        return src;
      }
    }
  };
</script>

<style lang="scss">
  .document {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .document-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
