<template>
  <div>
    <span
      v-if="!modifiable"
      class="label label-lg label-inline"
      :class="[`label-light-${value ? 'success' : 'danger'}`]"
    >
      {{ $t(`enumActivationStatus.${Number(value)}`) }}
    </span>
    <b-button
      v-else
      size="sm"
      :variant="`light-${value ? 'success' : 'danger'}`"
      :class="{ 'cursor-default': disabled }"
      :disabled="disabled"
      @click="actionClick(value)"
      >{{ $t(`enumActivationStatus.${Number(value)}`) }}</b-button
    >
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';
  import { ActiveStatus } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';

  export default {
    name: 'AppActivationStatusLabel',
    mixins: [commonMixin],
    props: {
      value: {
        type: Boolean,
        default: false
      },
      modifiable: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isBuildInAction: {
        type: Boolean,
        default: false
      },
      id: {
        type: Number
      },
      statusLabelActionsObj: {
        type: Object,
        default: () => {
          return {
            module: '',
            state: '',
            actionSet: '',
            actionInitial: '',
            successAction: () => {},
            errorActions: () => {},
            title: '',
            message: ''
          };
        }
      }
    },
    computed: {
      setActiveInactiveComplete() {
        let obj = this.statusLabelActionsObj;

        if (this.isBuildInAction) {
          return this.$store.state[obj.module][obj.state].complete;
        } else {
          return false;
        }
      }
    },
    watch: {
      setActiveInactiveComplete() {
        let obj = this.statusLabelActionsObj;
        let response = this.$store.state[obj.module][obj.state];
        let title = obj.title;
        let initialStateAction = obj.actionInitial;
        let successAction = obj.successAction;
        let errorAction = obj.errorAction;

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction,
            errorAction
          );
        }
      }
    },
    methods: {
      openRequestSetActiveInactiveModal(id, status) {
        let obj = this.statusLabelActionsObj;
        let title = obj.title;
        let messages = [];
        let buttons = [];
        let data = {
          isActive: status ? ActiveStatus.INACTIVE : ActiveStatus.ACTIVE
        };

        messages.push(
          i18nHelper.getMessage('message.requestSetMessage', [
            obj.message.toLowerCase(),
            i18nHelper
              .getMessage(`enumActivationStatus.${status ? 0 : 1}`)
              .toLowerCase()
          ])
        );

        buttons.push(
          {
            text: i18nHelper.getMessage('label.cancel'),
            variant: 'white',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          },
          {
            text: i18nHelper.getMessage(
              `enumActivationStatus.${status ? 0 : 1}`
            ),
            isText: false,
            variant: status ? 'danger' : 'success',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.updateAction(id, data);
            }
          }
        );

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      actionClick(value) {
        if (this.statusLabelActionsObj.actionSet) {
          this.openRequestSetActiveInactiveModal(this.id, value);
        } else {
          this.$emit('app-status-label-action-click', value);
        }
      },
      updateAction(id, data) {
        this.$store.dispatch(this.statusLabelActionsObj.actionSet, {
          id,
          data
        });
      }
    }
  };
</script>

<style></style>
