<template>
  <div class="chat-history-details">
    <b-row no-gutters class="chat-history-details__row">
      <b-col cols="4">
        <!-- begin:: Overview Card -->
        <ChatHistoryOverviewCard
          :value="chatObj"
          class="mb-4"
        ></ChatHistoryOverviewCard>
        <!-- end:: Overview Card -->

        <!-- begin:: User Card -->
        <ChatHistoryUsersCard :value="chatObj"></ChatHistoryUsersCard>
        <!-- end:: User Card -->
      </b-col>
      <b-col cols="8" class="pl-8">
        <!-- begin:: Messages Card -->
        <ChatHistoryMessagesCard :value="chatObj"> </ChatHistoryMessagesCard>
        <!-- end:: Messages Card -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import ChatHistoryOverviewCard from '@/views/components/chat-history/ChatHistoryOverviewCard';
  import ChatHistoryUsersCard from '@/views/components/chat-history/ChatHistoryUsersCard';
  import ChatHistoryMessagesCard from '@/views/components/chat-history/ChatHistoryMessagesCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    CHAT_GET_CHAT,
    CHAT_INITIAL_GET_CHAT_STATE
  } from '@/core/store/chat.module';

  export default {
    name: 'ChatHistoryDetails',
    components: {
      ChatHistoryOverviewCard,
      ChatHistoryUsersCard,
      ChatHistoryMessagesCard
    },
    mixins: [commonMixin],
    data: () => ({
      id: null,
      chatObj: {
        chatGroup: {},
        chatGroupMessage: []
      }
    }),
    computed: {
      chatComplete() {
        return this.$store.state.chat.chat.complete;
      }
    },
    watch: {
      chatComplete() {
        let response = this.$store.state.chat.chat;
        let title = i18nHelper.getMessage('label.getChat');
        let initialStateAction = CHAT_INITIAL_GET_CHAT_STATE;
        let successAction = (response) => {
          this.getChatSucceedAction(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initChatHistoryDetails();
    },
    methods: {
      checkNumberOddOrEven(number) {
        if (number % 2 == 0) {
          return 'even';
        } else {
          return 'odd';
        }
      },
      refreshChatHistoryDetailsAction() {
        this.getChat(this.id);
      },
      getChatSucceedAction(response) {
        let data = response.data;

        data.chatGroup.users.forEach((x, index) => {
          let position = 'right';

          if (this.checkNumberOddOrEven(index) == 'even') {
            position = 'left';
          }

          x.position = position;
        });

        this.chatObj = {
          ...data
        };
      },
      getChat(id) {
        this.$store.dispatch(CHAT_GET_CHAT, {
          id
        });
      },
      initChatHistoryDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.chatHistory'),
              route: { name: ROUTE_NAME.CHAT_HISTORY }
            },
            { title: i18nHelper.getMessage('label.chatHistoryDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getChat(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
